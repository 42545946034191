import SimpleSchema from 'simpl-schema'

export default {
  _id: {
    type: String,
    visibility: false
  },
  name: {
    label: 'Nombre',
    type: String
  },
  identifier: {
    label: 'Identificador',
    type: String
  },
  category: {
    label: 'Categoría',
    type: String,
    model: 'v2/analytic-categories',
    autocomplete: false,
  },
  groupId: {
    label: 'Grupo',
    type: Number,
    optional: true,
    model: 'groups',
    module: 'lpr',
    autocomplete: false
  },
  _location: {
    label: 'Ubicación',
    type: String,
    location: true,
    fields: ['latitude', 'longitude'],
  },
  longitude: {
    label: 'Longitud',
    type: Number,
    visibility: false,
  },
  latitude: {
    label: 'Latitud',
    type: Number,
    visibility: false,
  },
  address: {
    label: 'Dirección',
    type: String
  },
  sensors: {
    label: 'sensores',
    type: Array,
    title: (item) => {
      return item.id;
    },
    optional: true,
  },
  'sensors.$': {
    type: {
      id: {
        label: 'Identificador',
        type: String,
      },
      ip: {
        label: 'Dirección IP',
        type: String,
      },
      status: {
        label: 'Status',
        type: String,
      },
    },
  },
};
